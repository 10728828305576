import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import Toptitle from "../../components/toptitle"

const Construct = () => (
  <Layout>
    <SEO title="製品紹介" />
    <div>
      <section className="mr-0 ml-auto lg:w-2/3">
        <Toptitle>Products</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">製品紹介</h1>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-4">
          <CardWrapper className="flex w-full flex-col">
            <StaticImage
              src="../../images/products/molding.jpg"
              alt="モールディングの事例"
              className="w-full"
            />
            <div className="mb-2 flex flex-grow flex-col bg-secondery py-4 px-8">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                モールディング
              </h2>
              <p className="h-full pb-8 font-gothic leading-loose">
                天井廻り縁や額縁などに利用される装飾材の総称で、モルダーという特殊な設備を使い製作します。
                <br />
                オーダメイドで様々な形状に対応できる他、曲面仕様も可能です。
              </p>
              <Link
                to="/products/molding"
                className="mt-auto py-2 font-mincho text-primary underline underline-offset-4"
              >
                製品詳細
              </Link>
            </div>
          </CardWrapper>
          <CardWrapper className="flex w-full flex-col">
            <StaticImage
              src="../../images/products/louver.jpg"
              alt="ルーバーの事例"
              className="w-full"
            />
            <div className="mb-2 flex flex-grow flex-col bg-secondery py-4 px-8">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                ルーバー
              </h2>
              <p className="h-full pb-8 font-gothic leading-loose">
                商業施設、マンションエントランスやオフィスなど幅広く納品実績があります。
                <br />
                アルミや木目調などの仕上げまでも対応可能です。
              </p>
              <Link
                to="/products/louver"
                className="mt-auto py-2 font-mincho text-primary underline underline-offset-4"
              >
                製品詳細
              </Link>
            </div>
          </CardWrapper>
          <CardWrapper className="flex w-full flex-col">
            <StaticImage
              src="../../images/products/mikiri.jpg"
              alt="見切りの事例"
              className="w-full"
            />
            <div className="mb-2 flex flex-grow flex-col bg-secondery py-4 px-8">
              <h2 className="py-2 font-mincho text-2xl text-primary">見切り</h2>
              <p className="h-full pb-8 font-gothic leading-loose">
                クロスの差し目地や壁見切り、天井-壁の入隅見切りなどをはじめ、その他にも耐熱性能を活かし、間接照明の周辺材料やカーテンボックスなどにも利用いただけます。
              </p>
              <Link
                to="/products/mikiri"
                className="mt-auto py-2 font-mincho text-primary underline underline-offset-4"
              >
                製品詳細
              </Link>
            </div>
          </CardWrapper>
          <CardWrapper className="flex w-full flex-col">
            <StaticImage
              src="../../images/products/panel.jpg"
              alt="デザインパネルの事例"
              className="w-full"
            />
            <div className="mb-2 flex flex-grow flex-col bg-secondery py-4 px-8">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                デザインパネル
              </h2>
              <p className="h-full pb-8 font-gothic leading-loose">
                表面に凹凸がされており、照明を当てることで美しい陰影が表現できます。
                <br />
                空間のアクセントとして壁面に利用される他にもアートパネルとしても利用いただけます。
              </p>
              <Link
                to="/products/panel"
                className="mt-auto py-2 font-mincho text-primary underline underline-offset-4"
              >
                製品詳細
              </Link>
            </div>
          </CardWrapper>
          <CardWrapper className="flex w-full flex-col">
            <StaticImage
              src="../../images/products/sign.jpg"
              alt="サインの事例"
              className="w-full"
            />
            <div className="mb-2 flex flex-grow flex-col bg-secondery py-4 px-8">
              <h2 className="py-2 font-mincho text-2xl text-primary">サイン</h2>
              <p className="h-full pb-8 font-gothic leading-loose">
                CNCルーターを利用することでデザインデータ通りに削り出すことができるため、オーダメイドでサインの製作も可能です。
              </p>
            </div>
          </CardWrapper>
        </div>
      </section>
    </div>
  </Layout>
)

const CardWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    margin-top: 32px;
  }
`

export default Construct
